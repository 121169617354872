import { ref } from 'vue';
import { sideMenuItem } from 'types/Staff-Dashboard/SideMenu';

export const contractorsMenu = ref<sideMenuItem[]>([
  {
    name: 'Dashboard',
    route: 'Dashboard',
    icon: 'house-blank',
    aliases: [],
    children: []
  },
  {
    name: 'Procurement',
    icon: 'box',
    children: [{ name: 'Procurements', route: 'ProcurementServices', aliases: ['ProcurementServiceDetail'] }]
  },
  {
    name: 'Field Services',
    icon: 'file-circle-check',
    children: [
      { name: 'Resource Calendar', route: 'OpenVisit', aliases: [] },
      { name: 'Visit Management', route: 'VisitManagementDashboard', aliases: [] }
    ]
  },
  {
    name: 'TAC',
    icon: 'file-lines',
    children: [{ name: 'Ping Tool', route: 'PingTool', aliases: [''] }]
  }
]);
export const MenuItems = ref<sideMenuItem[]>([
  {
    name: 'Dashboard',
    route: 'Dashboard',
    icon: 'house-blank',
    aliases: [],
    children: []
  },
  {
    name: 'Customers',
    route: 'CustomersList',
    icon: 'users',
    aliases: ['CustomerCenter'],
    children: []
  },
  {
    name: 'Inventory',
    icon: 'house-blank',
    children: [
      { name: 'Inventory List', route: 'InventoryList', aliases: ['InventoryDetails'] },
      { name: 'Make/Model', route: 'Make', aliases: [] }
    ]
  },
  {
    name: 'Engineering',
    icon: 'toolbox',

    children: [
      {
        name: 'Config Generator',
        route: '',
        aliases: [],
        children: [
          { name: 'Templates', route: 'Templates', aliases: [] },
          { name: 'Batches', route: 'Batches', aliases: ['BatchDetails'] }
        ]
      }
    ]
  },
  {
    name: 'Procurement',
    icon: 'box',

    children: [
      { name: 'Qualifications', route: 'Qualifications', aliases: ['QualificationDetail'] },
      {
        name: 'Orders',
        route: '',
        aliases: ['OrderDetail'],
        children: [
          { name: 'All Orders', route: 'AllOrders', aliases: ['OrderDetail', 'Orders'] },
          { name: 'Approve to Order', route: 'ApprovedOrders', aliases: [''] },
          { name: 'Order Follow Up', route: 'FollowUpOrders', aliases: [''] },
          { name: 'Pending Install', route: 'PendingOrders', aliases: [''] }
        ]
      },
      {
        name: 'Cancellations',
        route: '',
        aliases: ['CancellationDetail'],
        children: [
          { name: 'Open', route: 'OpenCancellations', aliases: ['CancellationDetail', 'Cancellations'] },
          { name: 'Accounting', route: 'AccountingCancellations', aliases: [''] },
          { name: 'PM Review', route: 'PmReviewCancellations', aliases: [''] },
          { name: 'All', route: 'AllCancellations', aliases: [''] },
          { name: 'Closed', route: 'ClosedCancellations', aliases: [''] }
        ]
      },
      { name: 'Procurements', route: 'ProcurementServices', aliases: ['ProcurementServiceDetail'] },
      { name: 'Providers', route: 'ProviderManagement', aliases: [] }
    ]
  },
  {
    name: 'Data Usage',
    icon: 'folders',

    children: [
      { name: 'Plan Mapper', route: 'DataUsageCustomerPlanMapper', aliases: [] },
      { name: 'Requests', route: 'DataUsageRequestsList', aliases: [] },
      { name: 'Plans', route: 'DataUsagePlans', aliases: ['DataUsagePlansDetail'] },
      { name: 'Data Usage', route: 'DataUsage', aliases: ['DataUsageDetails'] },
      { name: 'Data Usage History', route: 'DataUsageHistory', aliases: ['DataUsageHistoryDetails'] },
      { name: 'Cradle Point', route: 'CradlePoint', aliases: [] },
      { name: 'Verizon', route: 'Verizon', aliases: [] }
    ]
  },
  {
    name: 'Accounting',
    icon: 'receipt',
    children: [
      {
        name: 'TEM',
        route: '',
        aliases: ['TemAccount', 'Tem', 'TemFunding', 'TemBills', 'TemBillMfa'],
        children: [
          { name: 'TEM', route: 'Tem', aliases: [] },
          { name: 'TEM Funding', route: 'TemFunding', aliases: [] },
          { name: 'TEM Bills', route: 'TemBills', aliases: [] },
          { name: 'TEM Bills MFA', route: 'TemBillMfa', aliases: [] }
        ]
      }
    ]
  },
  {
    name: 'Field Services',
    icon: 'file-circle-check',
    children: [
      { name: 'Job Requests', route: 'JobRequests', aliases: ['AddEditJobRequest'] },
      { name: 'Job Templates', route: 'JobTemplates', aliases: ['AddEditJobTemplate'] },
      { name: 'Job Booking', route: 'JobBookingQueue', aliases: [] },
      { name: 'My Visits', route: 'MyVisit', aliases: [] },
      { name: 'Work Order Review', route: 'WorkOrderReview', aliases: [] },
      { name: 'Logistics Queue', route: 'LogisticsQueue', aliases: [] },
      { name: 'EVM Billing Review', route: 'EVMBillingReview', aliases: [] },
      { name: 'Schedule of Fees', route: 'SOFManager', aliases: [] },
      { name: 'FST Accounting', route: 'FstAccounting', aliases: [] },
      { name: 'Resource Calendar', route: 'OpenVisit', aliases: [] },
      {
        name: 'Dashboard',
        route: '',
        aliases: [],
        children: [
          { name: 'Visit Management', route: 'VisitManagementDashboard', aliases: [] },
          { name: 'Full Screen VM', route: 'FullScreenVisitManagementDashboard', aliases: [] }
        ]
      },
      { name: 'Agent Management', route: 'AgentManagement', aliases: [''] }
    ]
  },
  {
    name: 'TAC',
    icon: 'file-lines',
    children: [
      { name: 'Support Tickets', route: 'Tickets', aliases: [''] },
      { name: 'Outage Manager', route: 'OutageManager', aliases: [''] },
      { name: 'Call Queue', route: 'CallQueue', aliases: [''] },
      { name: 'Ping Tool', route: 'PingTool', aliases: [''] },
      { name: 'Monitoring', route: 'Monitoring', aliases: [''] }
    ]
  },
  {
    name: 'Project Management',
    icon: 'bars-progress',
    children: [
      { name: 'PM Review', route: 'PMReviews', aliases: [''] },
      { name: 'PM Review History', route: 'PMReviewsHistory', aliases: [''] }
    ]
  },
  {
    name: 'Report',
    icon: 'memo',
    route: 'AuditLog',
    count: 32,
    aliases: [],
    children: []
  },
  {
    name: 'Report Sub Child',
    icon: 'memo',
    route: '',
    count: 32,
    aliases: [],
    children: [
      { name: 'Audit Logs', route: 'AuditLog', aliases: [''] },
      { name: 'Macros', route: 'Macros', aliases: [''] }
    ]
  },
  {
    name: 'Setting',
    icon: 'gear',
    children: [
      { name: 'Vendor Management', route: 'VendorManagement', aliases: [''] },
      { name: 'Audit Logs', route: 'AuditLog', aliases: [''] },
      { name: 'Macros', route: 'Macros', aliases: [''] },
      {
        name: 'Permission Management',
        route: '',
        aliases: [''],
        children: [
          { name: 'User Permission List', route: 'PermissionManagement', aliases: [''] },
          { name: 'Assign Role to Groups', route: 'RoleManagement', aliases: ['RoleManagementList'] }
        ]
      }
    ]
  }
]);
