export const removeMappingOfPlans = 1;
export const enablingOrDisablingDataAllowances = 2;
export const editCellularOverageCharge = 3;
export const userCanSavePlanMapper = 4;
export const userCanSaveAgents = 8;
export const userCanEditAgents = 9;
export const userCanDeleteAgents = 10;
export const userCanVerifyNewUserByIsVerifiedCheckBoxInCustomerCenter = 16;
export const userCanDeleteAttachments = 24;
export const userCanManageTemBills = 20;
export const userCanSaveMacros = 21;
export const userCanEditMacros = 22;
export const userCanDeleteMacros = 23;
export const contractorAccess = 25;
export const userCanSyncWithDynamics = 26;
export const userApproveDeniedBills = 27;
